import axios from "axios";
import { blendModuleAPI } from "../../../config";
let module = "/v1/BlendType";
export default {
  async get() {
    return axios
      .get(`${blendModuleAPI}${module}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  async create(data) {
    await axios
      .post(`${blendModuleAPI}${module}`, {
        name: data.name,
      })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async edit(id, data) {
    axios
      .put(`${blendModuleAPI}${module}/${id}`, { name: data.name })
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  async delete(id) {
    axios
      .delete(`${blendModuleAPI}${module}/${id}`)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
